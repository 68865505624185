@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap");

.ielts3-section-1 {
  background-image: linear-gradient(140deg, #022260 32%, #224b9a 72%);
  color: white;
}

.ielts3-section-1 h1 {
  font-size: clamp(25px, 2.5vw, 60px);
  display: inline-block;
  color: goldenrod;
}

.ielts3-section-1 p {
  font-size: clamp(15px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
  text-align: justify;
}

.ielts3-section-1 .starburst {
  width: 400px;
  aspect-ratio: 2/1;
  clip-path: polygon(99.4% 57.7%,
      85.05% 64.67%,
      90.16% 79.78%,
      74.22% 79.28%,
      71.73% 95.03%,
      57.84% 87.18%,
      48.31% 99.97%,
      39.66% 86.57%,
      25.28% 93.46%,
      23.85% 77.57%,
      7.91% 77%,
      14.03% 62.27%,
      0.19% 54.34%,
      12.45% 44.15%,
      3.88% 30.7%,
      19.47% 27.37%,
      18.13% 11.47%,
      33.49% 15.77%,
      39.68% 1.08%,
      51.29% 12.02%,
      63.6% 1.89%,
      68.79% 16.97%,
      84.4% 13.72%,
      81.99% 29.48%,
      97.32% 33.86%,
      87.86% 46.7%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: goldenrod;
  font-size: 16px;
  color: #000;
  font-weight: bold;
  position: relative;
  animation: blinkBackground 1s infinite alternate;
  cursor: pointer;
}

@keyframes blinkBackground {
  0% {
    background: rgb(255, 236, 61);
  }

  50% {
    background: rgb(218, 199, 32);
  }

  100% {
    background: rgb(255, 236, 61);
  }
}

.ielts3-section-1 .offer-price {
  font-size: 40px;
  color: red;
}

/* .ielts3-section-1 .old-offer-price {
    position: absolute;
    background: white;
    color: red;
} */

.ielts3-section-1 .offer-line {
  animation: shake 1s linear infinite;
  position: absolute;
  bottom: 50px;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(0px);
  }

  75% {
    transform: translateX(10px);
  }
}

.ielts3-section-2 {
  background: #e3efff;
  color: white;
}

.ielts3-section-2 h1 {
  font-size: clamp(25px, 2.5vw, 60px);
  display: inline-block;
  color: #224a98;
}

.ielts3-section-2 p {
  font-size: clamp(15px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
  text-align: justify;
  /* color: #414C68; */
  color: #fff;
}

.ielts3-section-2 .upgrade-sec {
  /* background: white; */
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000021;
  padding: 1em;
  border-radius: var(--r) var(--r) min(var(--r), 100% - var(--p) - var(--b) / 4) min(var(--r), var(--p) - var(--b) / 4) / var(--r);
  background: #cc333f;
  position: relative;
}

.ielts3-section-2 .upgrade-sec:hover {
  transform: translateY(-10px);
}

.upgrade-sec:first-child {
  --b: 3em;
  --p: 24%;
  --r: 1.2em;
}

.upgrade-sec:first-child:before {
  content: "";
  position: absolute;
  top: 100%;
  left: clamp(0%, var(--p) - var(--b) / 4, 100% - var(--b) / 2);
  width: var(--b);
  aspect-ratio: 1;
  background: inherit;
  --g: #000 calc(100% - 1px), #0000;
  -webkit-mask: radial-gradient(circle closest-side at 88% 88%, var(--g)),
    radial-gradient(20% 20% at 48% 55%, var(--g)),
    radial-gradient(25% 25% at 25% 0, var(--g));
}

.upgrade-sec:last-child {
  --b: 3em;
  --p: 76%;
  --r: 1.2em;
}

.upgrade-sec:last-child:before {
  content: "";
  position: absolute;
  top: 100%;
  left: clamp(var(--b) / -2, var(--p) - 3 * var(--b) / 4, 100% - var(--b));
  width: var(--b);
  aspect-ratio: 1;
  background: inherit;
  --g: #000 calc(100% - 1px), #0000;
  -webkit-mask: radial-gradient(circle closest-side at 12% 88%, var(--g)),
    radial-gradient(20% 20% at 52% 55%, var(--g)),
    radial-gradient(25% 25% at 75% 0, var(--g));
}

.ielts3-section-2 .or-sec {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ielts3-section-2 .inner-curve {
  display: flex;
  gap: 10px;
  background: #CC333F;
  padding: 10px;
  border-radius: 10px;
}

.ielts3-section-2 .inner-curve img {
  flex: none;
  width: 100px;
  height: fit-content;
}

.ielts3-section-2 h3 {
  font-size: clamp(20px, 1.5vw, 30px);
  color: #fff;
}


.ielts3-section-8 {
  background: #e3efff;
  color: white;
}

.ielts3-section-8 h1 {
  font-size: clamp(25px, 2.5vw, 60px);
  display: inline-block;
  color: #224a98;
}

.ielts3-section-8 p {
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
}

.ielts3-section-8 .swiper {
  padding: 0;
}

.ielts3-section-8 .swiper-slide {
  color: #000;
  padding: 10px;
  border-radius: 8px;
  height: fit-content;
}

.ielts3-section-8 .swiper-pagination-bullet {
  background: #fff;
}

.ielts3-section-8 .testimonial {
  text-align: center;
  color: #f2efe8;
  margin: 36px 30px 55px;
  padding: 30px 40px;
  border-radius: 30px;
  position: relative;
  z-index: 1;
}

.ielts3-section-8 .testimonial:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #224a98;
  border-radius: 30px;
  z-index: -1;
}

.ielts3-section-8 .testimonial:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #cc333f;
  border-radius: 30px;
  transform: rotate(-7deg);
  z-index: -2;
}

.ielts3-section-8 .testimonial .testimonial-content {
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 30px;
  margin-bottom: 20px;
  text-align: start;
  height: 396px;
  overflow: scroll;
  scrollbar-width: none;
  font-family: "JetBrains Mono", serif;
  font-weight: 100;
  word-spacing: 5px;
}

.ielts3-section-8 .testimonial .testimonial-title {
  line-height: 20px;
}

.ielts3-section-8 .testimonial .testimonial-title a {
  display: inline-block;
  color: #fb8c00;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}

.ielts3-section-8 .testimonial .testimonial-title small {
  color: #f2efe8;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.swiper-button-next,
.swiper-button-prev {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #cc333f;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px;
  color: #fff;
}

.swiper-button-prev {
  position: absolute;
  bottom: 0 !important;
  right: 60px !important;
  top: auto;
  left: auto;
}

.swiper-button-next {
  position: absolute;
  bottom: 0 !important;
  right: 0px !important;
  top: auto;
  left: auto;
}

.ielts3-section-3 {
  background: #224a98;
  color: #fff;
}

.ielts3-section-3 h1 {
  font-size: clamp(25px, 2.5vw, 60px);
  display: inline-block;
  color: #fff;
}

.ielts3-section-3 p {
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
  text-align: justify;
  color: #fff;
}

.ielts3-section-4 {
  background: #e3efff;
  color: #224a98;
}

.ielts3-section-4 h1 {
  font-size: clamp(25px, 2.5vw, 60px);
  display: inline-block;
  color: #224a98;
}

.ielts3-section-4 p {
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
  margin: 0;
}

.ielts3-section-4 .list-item {
  padding: 10px 26px 10px 10px;
  border-radius: 5px;
  background: #cc333f;
  color: #fff;
  --s: 20px;
  clip-path: polygon(0 0,
      calc(100% - var(--s)) 0,
      100% 50%,
      calc(100% - var(--s)) 100%,
      0 100%);
  width: fit-content;
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.ielts3-section-4 .list-item:hover {
  background: #fa5563;
  color: #fff;
  transform: translateX(10px);
}

.ielts3-section-4 .test-question-icon {
  position: absolute;
  right: 25px;
  top: 30%;
  font-size: 250px;
  opacity: 0.2;
  transform: rotate(15deg);
}

.ielts3-section-4 .list-icon {
  color: limegreen;
  font-size: clamp(20px, 2.5vw, 30px);
  flex: none;
}

.ielts3-section-5 {
  background-image: linear-gradient(140deg, #000000 32%, #224b9a 72%);
  color: white;
}

.ielts3-section-5 h1 {
  color: #fff;
  font-size: clamp(20px, 2.5vw, 50px);
}

.ielts3-section-5 p {
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
}

.ielts3-section-6 {
  background: #e3efff;
  color: #fff;
}

.ielts3-section-6 h1 {
  font-size: clamp(25px, 2.5vw, 60px);
  display: inline-block;
  color: #224a98;
}

.ielts3-section-6 h3 {
  font-size: clamp(20px, 1.5vw, 30px);
  display: inline-block;
  color: #f0be37;
}

.ielts3-section-6 p {
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
}

.ielts3-section-6 .process-card {
  background: #224a98;
  display: flex;
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
  height: 100%;
}

.ielts3-section-6 img {
  border: 1px solid black;
  width: 30%;
  height: fit-content;
  flex: none;
  max-width: 140px;
  align-self: center;
}

.ielts3-section-7 {
  background: #cc333f;
  color: #fff;
}

.ielts3-section-7 h1 {
  font-size: clamp(25px, 2.5vw, 60px);
  display: inline-block;
  color: #fff;
}

.ielts3-section-7 h2 {
  font-size: clamp(20px, 2vw, 50px);
  display: inline-block;
  color: #fff;
}

.ielts3-section-7 p {
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
  color: #fff;
}

.ielts3-section-7 .ctc-btn {
  background: #fff;
  text-decoration: none;
  color: black;
  padding: 10px 15px;
  border-radius: 10px;
  font-weight: 600;
  margin: 15px 0 0;
  display: inline-block;
  cursor: pointer;
}

.ielts3-section-7 .ctc-btn:hover {
  background: #214995;
  color: #fff;
  transition: all 0.2s linear;
}

/* HTML: <div class="loader"></div> */
.ielts3-section-7 .loader-text {
  width: fit-content;
  font-size: 40px;
  font-family: system-ui, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #0000;
  -webkit-text-stroke: 1px yellow;
  background: conic-gradient(yellow 0 0) text;
  animation: l8 2s linear infinite;
  line-height: normal;
  margin: auto;
}

.ielts3-section-7 .loader-text:before {
  content: "Hurry!";
}

@keyframes l8 {

  0%,
  2%,
  8%,
  11%,
  15%,
  21%,
  30%,
  32%,
  35%,
  40%,
  46%,
  47%,
  53%,
  61%,
  70%,
  72%,
  77%,
  80%,
  86% {
    background-size: 0 0;
  }

  1%,
  9%,
  10%,
  16%,
  20%,
  31%,
  34%,
  41%,
  45%,
  48%,
  52%,
  55%,
  60%,
  73%,
  76%,
  81%,
  85%,
  96%,
  100% {
    background-size: 100% 100%;
  }
}

.timeline {
  position: relative;
}

.timeline::before {
  content: "";
  background: #cc333f;
  width: 5px;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.timeline-item {
  width: 100%;
  margin-bottom: 70px;
}

.timeline-item:nth-child(even) .timeline-content {
  float: right;
  padding: 10px 30px;
  position: relative;
}

.timeline-item:nth-child(even) .timeline-content::after {
  content: "";
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
  top: 30px;
  left: -15px;
  border-width: 10px 15px 10px 0;
  border-color: transparent #fff transparent transparent;
}

.timeline-item::after {
  content: "";
  display: block;
  clear: both;
}

.timeline-content {
  position: relative;
  width: 45%;
  padding: 10px 30px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.3);
}

.timeline-content::after {
  content: "";
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
  top: 30px;
  right: -15px;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #fff;
}

.timeline-img {
  width: 30px;
  height: 30px;
  background: #cc333f;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-top: 25px;
  margin-left: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
}

.timeline-card {
  padding: 0 !important;
}

.timeline-card p {
  padding: 0 20px;
}

.timeline-card a {
  margin-left: 20px;
}

.ielts3-section-9 {
  background: #224a98;
  color: #fff;
}

.ielts3-section-9 h1 {
  font-size: clamp(25px, 2.5vw, 60px);
  display: inline-block;
  color: #fff;
}

.ielts3-section-9 h3 {
  font-size: clamp(20px, 1.5vw, 30px);
  color: #fff;
}

.ielts3-section-9 p {
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
  text-align: justify;
  color: #fff;
}

.ielts3-section-9 .touch-btn {
  padding: 10px 30px;
  background: rgb(204, 50, 63);
  text-decoration: none;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  cursor: pointer;
  box-shadow: inset 2px 2px 5px #ffffff69, 2px 2px 5px #00000073;
}

.ielts3-section-9 .touch-btn:hover {
  /* background: rgb(255, 49, 66); */
  box-shadow: none;
}

.ielts3-section-9 .faq-container {
  background: #fff;
  box-shadow: 0 8px 32px 0 rgb(72 72 72 / 37%);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgb(255 255 255 / 18%);
  margin: 0 5px;
}

.ielts3-section-9 .accordion-item {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}

.ielts3-section-9 .accordion-body {
  padding: 20px 20px 20px 60px !important;
}

.ielts3-section-9 button.accordion-button.collapsed {
  background: #d9e8ff !important;
}

.ielts3-section-9 button.accordion-button {
  background: #d9e8ff !important;
  padding: 10px !important;
  margin: 0;
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
}

.ielts3-section-9 .certy-container {
  position: absolute;
  right: 0;
  top: 0;
}

.ielts3-section-9 .certy {
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 20px);
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.ielts3-section-9 .certy {
  --r: 0.8em;
  border-inline: 0.5em solid #0000;
  padding: 10px 5px 20px;
  clip-path: polygon(0 0,
      100% 0,
      100% 100%,
      calc(100% - 0.5em) calc(100% - var(--r)),
      50% 100%,
      0.5em calc(100% - var(--r)),
      0 100%);
  background: radial-gradient(50% 0.2em at top, #000a, #0000) border-box,
    #bd1550 padding-box;
  width: fit-content;
}

/* FOOTER SECTION */

.itelts3-footer-section {
  background: #061b44;
  /* height: 50vh; */
}

.itelts3-footer-section img.logo {
  background: #fff;
  padding: 5px;
}

.itelts3-footer-section .f-contact {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  color: #fff;
}

.itelts3-footer-section .f-contact i {
  color: #bd1550;
}

.itelts3-footer-section .f-contact a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.itelts3-footer-section .social-media {
  display: flex;

}

.itelts3-footer-section .f-social-media {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #fff;
}

.itelts3-footer-section .f-social-media i {
  font-size: 24px;
  background: #fff;
  padding: 7px;
  border-radius: 5px;
}

.itelts3-footer-section .f-social-media i.bi-facebook,
.itelts3-footer-section .f-social-media a.facebook-text {
  color: #1877f2;
  filter: drop-shadow(0 -1px 0px #dadada);
}

.itelts3-footer-section .f-social-media i.bi-instagram,
.itelts3-footer-section .f-social-media a.instgram-text {
  color: #dc0032;
  filter: drop-shadow(0 -1px 0px #dadada);
}

.itelts3-footer-section .f-social-media i.bi-linkedin,
.itelts3-footer-section .f-social-media a.linkedin-text {
  color: #0077b5;
  filter: drop-shadow(0 -1px 0px #dadada);
}

.itelts3-footer-section .f-social-media a {
  text-decoration: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.itelts3-footer-section .f-social-media a.linkedin-text {
  color: #0077b5;
}

.ielts3-section-10 {
  background: #E3EFFF;
  color: #224A98;
}

.ielts3-section-10 h1 {
  font-size: clamp(25px, 2.5vw, 60px);
  display: inline-block;
  color: #224A98;
}

.ielts3-section-10 h3 {
  font-size: clamp(18px, 1.5vw, 25px);
  color: #224A98;
  font-weight: 500;
}

.ielts3-section-10 .logo {
  width: 150px;
}

.ielts3-section-10 a {
  color: #224A98 !important;
  text-decoration: none !important;
  position: relative;
}

.ielts3-section-10 a i {
  position: absolute;
  font-size: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.ielts3-section-10 .footer-branch-sec ul li a {
  color: #fff !important;
  background: #224A98;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
}

.ielts3-section-10 .footer-branch-sec ul li a:hover,
.ielts3-section-10 .footer-branch-sec ul li a:focus {
  color: #000 !important;
  background: aliceblue;
  transition: all 0.2s linear;
}

.ielts3-section-10 .footer-top-para {
  display: flex;
  gap: 10px;
  padding: 10px 0;
}

.ielts3-section-10 .footer-top-para-right {
  width: 50%;
}

.ielts3-section-10 .footer-top-para-right p {
  display: flex;
  align-items: center;
}

.ielts3-section-10 .footer-socialmedia :hover {
  background-color: transparent;
  color: #000;
}

.ielts3-section-10 .map-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  position: relative;
  aspect-ratio: 1/1;
  max-width: 450px;
}

.ielts3-section-10 .box {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid lightgray;
}

.ielts3-section-10 .box-name {
  position: absolute;
  background: #0000006e;
  top: 0;
  right: 0;
  color: #fff;
  padding: 2px 5px;
  border-radius: 0 0 0 7px;
}

.ielts3-section-10 .box:hover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  transition: all 0.3 linear;
}

@media (max-width: 992px) {
  .ielts3-section-1 .starburst {
    margin: 0 auto 20px;
  }

  .ielts3-section-8 .testimonial .testimonial-content {
    height: 200px;
  }

  .ielts3-section-9 .faq-container .man-thinking-img {
    max-width: 200px;
    display: flex;
    justify-self: center;
  }

}

@media (max-width: 524px) {
  .ielts3-section-1 .starburst {
    width: 300px;
    margin: 0 auto 20px;
    font-size: 12px;
  }

  .ielts3-section-1 .offer-price {
    font-size: 30px;
  }

  .ielts3-section-1 .offer-line {
    bottom: 40px;
  }
}

@media (max-width: 575px) {
  .ielts3-section-10 .footer-top-para {
    display: block !important;
  }

  .ielts3-section-10 .footer-top-para-right {
    width: 100% !important;
  }
}


@media screen and (max-width: 768px) {
  .timeline::before {
    left: 30px;
  }

  .timeline-img {
    left: 30px;
  }

  .timeline-content {
    max-width: 100%;
    width: auto;
    margin-left: 60px;
  }

  .timeline-item:nth-child(even) .timeline-content {
    float: none;
  }

  .timeline-item:nth-child(odd) .timeline-content::after {
    content: "";
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    left: -15px;
    border-width: 10px 15px 10px 0;
    border-color: transparent #fff transparent transparent;
  }

  .ielts3-section-9 button.accordion-button {
    padding: 5px 20px !important;
  }
}